html, body {
    color: $color-foreground;
    margin: 1rem;
    padding: 0;
}

html {
    font-family: $sans-serif;
    font-size: $font-size;
    overflow-y: auto;
}

body {
    background-color: $color-background;
}

a {
    @include transition(color .2s ease-out);
    color: $color-foreground;

    &:hover {
        color: $color-hover;
    }
}

h1 {
    font-size: 9rem;
}

h2 {
    font-size: 3rem;
    font-weight: normal;
}

h3 {
    font-size: 2rem;
    font-weight: normal;
}

@media screen and (max-width: $break-large) {
    h1 {
        font-size: 15vw;
    }

    h2 {
        font-size: 2.5rem;
    }
}


img.portrait {
    box-sizing: border-box;
    border-radius: 50%;
    border: 10px solid $color-foreground;
    margin: 2em 3em;
    width: 100%;
    height: auto;
    max-width: 300px;
    max-height: 300px;
}

article {
    .postShort-title {
        font-size: $font-size;
    }
}
