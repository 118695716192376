// Colours
$color-background: #333745;
$color-foreground: #edf7ff;
$color-hover: #c7efcf;



$coverimage: "/images/topography.min.svg";

@import '../common/variables';
@import 'main/base';
@import 'main/layout';
